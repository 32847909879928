import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from 'react-router-dom'

import Loadable from 'react-loadable'

import AuthGuard from 'components/AuthGuard'
import Authentication from 'utils/Authentication'
import Logger from 'utils/Logger'
import EventEmitter from 'utils/EventEmitter'

import 'App.css';


const Loading = () => (
  <div />
)

const Logout = () => {
  const history = useHistory();

  React.useEffect(() => {
    Authentication.logout().then(() => {
      history.push('/login');
    })
  }, []);

  return (
    <div>Loading...</div>
  )
}

const AsyncLogin = Loadable({ loader: () => import("./layouts/Login"), loading: Loading })
const AsyncDashboard = Loadable({ loader: () => import("./layouts/Dashboard"), loading: Loading })
const AsyncNotFound = Loadable({ loader: () => import("./pages/NotFound"), loading: Loading })


export const GlobalContext = React.createContext(null)

export default (props) => {
  const [token, setToken] = React.useState(null)
  const [loader, setLoader] = React.useState(true)
  const [menuKey, setMenuKey] = React.useState(null)

  React.useEffect(() => {
    document.title = 'Unified UI Demo'

    Logger.log('App first run')

    EventEmitter.subscribe('setLoader', shown => {
      console.log('setLoader', shown)
      setLoader(shown)
    })
  }, [])

  const context = {
    _APIURL: 'http://localhost:3030',
    setToken: setToken,
    setLoader: setLoader,
    setMenuKey: setMenuKey,
    token: token,
    loader: loader,
    menuKey: menuKey
  }

  return (
    <Router>
      <GlobalContext.Provider value={context}>
        <div className={`central-loader ${loader ? 'animate' : 'loaded'}`}>
          <div className="loader-progress"></div>
          {/* <LoadingOutlined style={{ fontSize: 18 }} /> */}
        </div>
        <Switch>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/login">
            <AsyncLogin />
          </Route>
          <Route path="/">
            <AuthGuard component={AsyncDashboard} />
          </Route>
          <Route path="*">
            <AsyncNotFound />
          </Route>
        </Switch>
      </GlobalContext.Provider>

      {/* <div>{JSON.stringify(window.location.href)}</div> */}
    </Router>
  )
}
