import moment from 'moment'

const Logger = {
    log: (...msg) => {
        console.log(moment().format(), ...msg)
    },
    error: (...msg) => {
        console.error(moment().format(), ...msg)
    }
}

export default Logger