import client from 'utils/feathers-client'
import Logger from 'utils/Logger'


export default {
  prevPath: '/',
  events: {},
  userInfo: {},

  check: function () {
    this.prevPath = window.location.pathname || '/'

    Logger.log('Authentication check', 'prevPath', this.prevPath)
    return new Promise((resolve, reject) => {
      client.reAuthenticate().then(() => {
        client.get('authentication').then(auth => this.userInfo = auth.user)
        resolve()
      })
      .catch(() => reject())
    })
  },

  login: function (credentials) {
    return new Promise((resolve, reject) => {
      client.authenticate({ ...credentials, strategy: 'local' })
        .then(response => {
          Logger.log('Login OK, redirecting to', this.prevPath)
          client.get('authentication').then(auth => this.userInfo = auth.user)

          this.dispatch('loginSuccess', response)
          resolve(this.prevPath)
        })
        .catch((error, message) => {
          Logger.error('Login error', error, message)
          this.dispatch('loginError', (error, message))
          reject(error, message)
        })
    })
  },

  logout: function () {
    return new Promise((resolve, reject) => {
      this.prevPath = '/'
      // client.logout()
      window.localStorage.removeItem('auth-token')

      this.dispatch('logout', null)
      resolve()
    })
  },

  getUser: function () {
    return this.userInfo
  },

  dispatch: function (event, data) {
    if (!this.events[event]) return
    this.events[event].forEach(callback => callback(data))
  },

  subscribe: function (event, callback) {
    if (!this.events[event]) this.events[event] = []
    this.events[event].push(callback)
  }

}
