import feathers from '@feathersjs/client'
import io from 'socket.io-client'


// const socket = io('http://localhost:3030')
const socket = io('https://sms-app.unifiedweb.ph', {
  'path': '/api/socket.io'
})

const client = feathers()
    .configure(feathers.socketio(socket))
    .configure(
        feathers.authentication({
            storage: window.localStorage,
            storageKey: 'auth-token'
        })
    )

export default client
