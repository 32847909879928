import React from 'react'
import { Redirect } from 'react-router-dom'

import Authentication from 'utils/Authentication'
import Logger from 'utils/Logger'


export default (props) => {
  const [loggedin, setLoggedin] = React.useState(null)

  React.useEffect(() => {
    Logger.log('AuthGuard interception')

    Authentication.check().then(() => {
      setLoggedin(true)
      Logger.log('Token ok')
    })
    .catch(() => {
      setLoggedin(false)
    })
  }, [])

  if (loggedin === false) {
    Logger.log('Token expired or missing, redirect to /login')

    return (
      <Redirect to="/login" />
    )
  }
  else {
    return (
      <div className="AuthGuard">
        {React.createElement(props.component, { ...props })}
      </div>
    )
  }
}
